import React from 'react'
import './../assets/css/experiments.css'

class Experiments extends React.Component {
  render() {
    return (
      <div className="experiments-section">
        Sorry, under construction!
      </div>
    )
  }
}
export default Experiments