import React from 'react'
import './../../assets/css/headercontent.css'

interface IComponentProps {
  title?: string
  img?: any
  nonCollapsible?: boolean
}

interface IComponentState {
  switch: Boolean
  height: any
  heightImg: any
}

class HeaderContent extends React.Component<IComponentProps, IComponentState> {
  elementHeight: number = 0
  imgHeight: number = 0
  divElement: any
  img: any

  constructor(props: IComponentProps) {
    super(props)
    this.state = {
      switch : false,
      height : 0,
      heightImg : 0
    }
    this.toggleSwitch = this.toggleSwitch.bind(this)
    this.calculateElementHeight = this.calculateElementHeight.bind(this)
  }

  getScale() {
    let width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width
    return width/800.0>1?1:width/800.0
  }

  componentDidMount() {
    if (this.props.nonCollapsible) {
      this.setState({
        height: "auto"
      })
      return
    }
    this.calculateElementHeight()
    window.addEventListener("resize", this.resizeHandler.bind(this));
  }

  componentWillUnmount() {
    if (!this.props.nonCollapsible)
      window.removeEventListener("resize", this.resizeHandler.bind(this));
  }

  resizeHandler() {
    this.calculateElementHeight()
    if (this.state.height !== 0) {
      this.setState({
        height : this.elementHeight
      })
    }
  }

  calculateElementHeight() {
    if (this.divElement)
      this.elementHeight = this.divElement.scrollHeight
  }

  calculateImgHeight({target}: {target: any}) {
    this.setState({
      heightImg: target.naturalHeight
    })
  }

  toggleSwitch() {
    if (!this.props.nonCollapsible) {
      this.calculateElementHeight()
      this.setState({
        switch: !this.state.switch,
        height: this.state.switch?0:this.elementHeight,
      })
    }
  }
  
  render() {
    let images: any;
    let width: number = (window.innerWidth > 0) ? window.innerWidth : window.screen.width
    let scale: number = width/800.0>1?1:width/800.0
    let imgHeight: any

    if (this.state.heightImg===0 || this.state.heightImg<250)
      imgHeight = "auto"
    else if (this.state.switch) 
      imgHeight = this.state.heightImg*scale
    else
      imgHeight = 250*scale

    if (this.props.img) {
      images = <img
        onLoad={this.calculateImgHeight.bind(this)}
        onError={()=>this.setState({switch: true})}
        style={{ height: imgHeight }}
        alt="header"
        src={this.props.img}/>
    }

    return (
      <div className="header-content-section">
        <div className="header-content__header">
          <span className="header-content__header-title">{this.props.title}</span>
          <span className="header-content__header-line"><hr/></span>
        </div>
        <div 
          className={this.props.nonCollapsible?
            "header-content__header-img-noncollapsible":"header-content__header-img-collapsible"}
          onClick={()=>{this.toggleSwitch()}}
        >
          {images}
        </div>
        <div
          className={this.state.switch||this.props.nonCollapsible?
            "header-content__content-visible":"header-content__content-invisible"}
          style={{height: this.state.height}}
          ref={(divElement) => {this.divElement = divElement}}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}
export default HeaderContent