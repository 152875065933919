import React from 'react'
import Main from './components/Main'
import Fonts from './components/Fonts'
import Projects from './components/Projects'
import Experiments from './components/Experiments'
import { Route, Link, MemoryRouter as Router } from 'react-router-dom'
import './App.css'

interface IComponentProps {}

interface IComponentState {
  currentPage : string;
}

class App extends React.Component<IComponentProps, IComponentState> {
  routing = (
    <div>
      <Route
        exact path="/"
        component={ Main }
      />
      <Route
        path="/fonts" 
        component={ Fonts }
      />
      <Route
        path="/projects" 
        component={ Projects }
      />
      <Route
        path="/experiments" 
        component={ Experiments }
      />
    </div>
  )

  constructor(props:IComponentProps) {
    super(props)
    this.state = {
      currentPage: "home"
    }
    this.setCurrentPage = this.setCurrentPage.bind(this)
  }

  setCurrentPage(page:string) {
    this.setState({
      currentPage: page
    })
  }

  render() {
    return (
      <Router>
      <div className="Placeholder">
        <div className="Container">
          <header>
            <div className="name-section">
              <h1 className="name">dichi • </h1> <h1 className="section-title">{this.state.currentPage}</h1>
            </div>
            <div> {/* this div fixes flex problems */}
              <div className="links">
                <div className="link-container">
                  <Link
                    className={this.state.currentPage === "home" ? "link-disabled" : "link"}
                    onClick={() => this.setCurrentPage("home")}
                    to="/"
                  >
                    home
                  </Link>
                  <div className={this.state.currentPage === "home" ? "slideup" : "slidedown"}/>
                </div>
                <div className="link-container">
                  <Link
                    className={this.state.currentPage === "fonts" ? "link-disabled" : "link"}
                    onClick={() => this.setCurrentPage("fonts")}
                    to="/fonts"
                  >
                    fonts
                  </Link>
                  <div className={this.state.currentPage === "fonts" ? "slideup" : "slidedown"}/>
                </div>
                <div className="link-container">
                  <Link
                    className={this.state.currentPage === "projects" ? "link-disabled" : "link"}
                    onClick={() => this.setCurrentPage("projects")}
                    to="/projects"
                  >
                    projects
                  </Link>
                  <div className={this.state.currentPage === "projects" ? "slideup" : "slidedown"}/>
                </div>
                <div className="link-container">
                  <Link
                    className={this.state.currentPage === "experiments" ? "link-disabled" : "link"}
                    onClick={() => this.setCurrentPage("experiments")}
                    to="/experiments"
                  >
                    experiments
                  </Link>
                  <div className={this.state.currentPage === "experiments" ? "slideup" : "slidedown"}/>                
                </div>
              </div>
            </div>
          </header>
          {this.routing}
        </div>
        <footer>
          <div className="Container-footer">
            <div>
              <i className="fab fa-facebook">
                <a
                  target="_blank"
                  href="https://facebook.com/dichi13"
                  rel="noopener noreferrer"
                >
                  /dichi13
                </a>
              </i>
              <i className="fab fa-twitter">
                <a
                  target="_blank"
                  href="https://twitter.com/dichidichi"
                  rel="noopener noreferrer"
                >
                  @dichidichi
                </a>
              </i>
              <i className="fab fa-github">
                <a
                  target="_blank"
                  href="https://github.com/Dichi13"
                  rel="noopener noreferrer"
                >
                  dichi13
                </a>
              </i>
            </div>
            <div>
              <i>Made with React, with love.</i>
            </div>
          </div>
        </footer>       
      </div>
      </Router>
    );
  }
}

export default App;
