import React from 'react'
import './../../assets/css/button.css'

interface IComponentProps {
  title?: string;
  onClick?: any;
}

class ButtonModule extends React.Component<IComponentProps> {
  render() {
    return (
      <button
        className="button-module__button"
        onClick={this.props.onClick}
      >
        {this.props.title}
      </button>
    )
  }
}
export default ButtonModule