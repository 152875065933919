import React from 'react'
import './../assets/css/main.css'

class Main extends React.Component {
  render() {
    return (
      <div>
        <div className="heading__main">“Programming is <strong>a science</strong>,<br/>yet also it is <strong>an art</strong>.”</div>
        <h3>I'm Dichi, an aspiring developer and designer.</h3>
        <p>Sometimes I design things,<br/>sometimes I code,<br/>and sometimes I just slack off.</p>
      </div>
    )
  }
}
export default Main