import React from 'react'
import './../assets/css/fonts.css'
import HeaderContent from './partial/HeaderContent'
import ButtonModule from './partial/ButtonModule'
import Badge from './partial/Badge'

interface IComponentProps {
  title: string;
  img: any;
}

class Fonts extends React.Component<IComponentProps> {
  render() {
    return (
      <div className="fonts-section">
        <p>
          <b>Every glyph has its own important meaning that people can understand.</b>
          <br/>Hence, it is really important to know how those curves communicate with people.
          <br/>It's worth noting that doing such thing is a <strong>really difficult feat</strong>; it takes years for me to polish the design iteratively so that it looks usable and fun to use.
        </p>
        <br/>
        <HeaderContent title="Acephimere" img={require("./../assets/img/fonts/acephimere.png")}>
          <Badge title="sans serif" color="#104e3f"/>
          <Badge title="text" color="#10304e"/>
          <Badge title="humanist"/>
          <Badge title="extended latin"/>
          <p>
            Born as a reincarnation of Esphimere, Acephimere designed specifically to look stylish <strong>for text purposes</strong>. 
            Acephimere has a striking resemblance to Esphimere, but tweaked slightly to give the font more <strong>humanist feeling</strong> and to improve <strong>legibility on small sizes</strong>. 
          </p>
          <div className="button-section">
            <ButtonModule
              onClick={()=>window.open("https://www.dafont.com/acephimere.font", "_blank")}
              title="go to dafont"
            />
            <ButtonModule
              onClick={()=>window.open("https://www.creativefabrica.com/product/acephimere/ref/192267/", "_blank")}
              title="go to creative fabrica"
            />
          </div>
        </HeaderContent>
        <HeaderContent title="Esphimere" img={require("./../assets/img/fonts/esphimere.png")}>
          <Badge title="sans serif" color="#104e3f"/>
          <Badge title="display" color="#10304e"/>
          <Badge title="squared curve"/>
          <p>
            Esphimere is one of the unique font designed <strong>for display purposes</strong>, such as headings.
            Influenced by Exo and Titillium, Esphimere has a futuristic feel thanks to its <strong>squared curve design</strong>.
          </p>
          <div className="button-section">
            <ButtonModule
              onClick={()=>window.open("https://www.dafont.com/esphimere.font", "_blank")}
              title="go to dafont"
            />
            <ButtonModule
              onClick={()=>window.open("https://www.creativefabrica.com/product/esphimere/ref/192267/", "_blank")}
              title="go to creative fabrica"
            />
          </div>
        </HeaderContent>
        <HeaderContent title="De Luxe Next" img={require("./../assets/img/fonts/deluxenext.png")}>
          <Badge title="sans serif" color="#104e3f"/>
          <Badge title="display" color="#10304e"/>
          <p>
            De Luxe Next released as the <strong>next iteration of <span title="if that's not obvious enough">De Luxe</span></strong>. De Luxe Next has <strong>better spacing, kerning, and more natural curves</strong>.
          </p>
          <div className="button-section">
            <ButtonModule
              onClick={()=>window.open("https://www.dafont.com/de-luxe-next.font", "_blank")}
              title="go to dafont"
            />
          </div>
        </HeaderContent>
        <HeaderContent title="De Luxe" img={require("./../assets/img/fonts/deluxe.png")}>
          <Badge title="sans serif" color="#104e3f"/>
          <Badge title="display" color="#10304e"/>
          <Badge title="experimental" color="#61440f"/>
          <Badge title="humanist"/>
          <p>
            De Luxe is my first attempt to create a <strong>humanist sans serif</strong>.
          </p>
          <div className="button-section">
            <ButtonModule
              onClick={()=>window.open("https://www.dafont.com/de-luxe.font", "_blank")}
              title="go to dafont"
            />
          </div>
        </HeaderContent>
        <HeaderContent title="Prehistoric" img={require("./../assets/img/fonts/prehistoric.png")}>
          <Badge title="cartoon" color="#104e3f"/>
          <Badge title="display" color="#10304e"/>
          <Badge title="experimental" color="#61440f"/>
          <p>
            <b>Prehistoric</b> is an experimental decorative font.
            The challenge taken up is to compose a font <strong>with no curves on any of its glyphs</strong>, so it looks like text carved out of wood or stone that reminds me of the prehistoric era.
          </p>
          <div className="button-section">
            <ButtonModule
              onClick={()=>window.open("https://www.dafont.com/prehistoric2.font", "_blank")}
              title="go to dafont"
            />
          </div>
        </HeaderContent>
      </div>
    )
  }
}
export default Fonts