import React from 'react'
import './../assets/css/fonts.css'
import HeaderContent from './partial/HeaderContent'
import ButtonModule from './partial/ButtonModule'
import Badge from './partial/Badge'

interface IComponentProps {
  title: string;
  img: any;
}

class Projects extends React.Component<IComponentProps> {
  render() {
    return (
      <div className="fonts-section">
        <p>
          My past <strong>noteworthy</strong> project lies here.
        </p>
        <br/>
        <HeaderContent title="This very site!" img={require("./../assets/img/projects/web.jpg")}>
          <Badge title="React" color="#104e3f"/>
          <Badge title="web" color="#10304e"/>
          <Badge title="solo project"/>
          <p>
            This site was made to <strong>show all the project I worked in the past</strong>.
            This is also an opportunity for me to explore the possibility offered by React.
          </p>
          <div className="button-section">
            <ButtonModule
              onClick={()=>window.open("https://dichi13.github.io", "_blank")}
              title="go to site :))"
            />
          </div>
        </HeaderContent>
        <HeaderContent title="Death of the Debt" img={require("./../assets/img/projects/dotd.jpg")}>
          <Badge title="Vue" color="#104e3f"/>
          <Badge title="PWA" color="#10304e"/>
          <p>
            Death of the Debt is a PWA games made in Vue.
            To put it simply, it is a <strong>hide and seek games with extra gimmicks like location tracking and power-ups</strong>.
            Just create an account and you're ready to go play with others.
          </p>
          <div className="button-section">
            <ButtonModule
              onClick={()=>window.open("https://tenshi.dev", "_blank")}
              title="go to site"
            />
          </div>
        </HeaderContent>
        <HeaderContent title="APBD Jabar Visualization" img={require("./../assets/img/projects/apbd.jpg")}>
          <Badge title="Vue" color="#104e3f"/>
          <Badge title="Flask" color="#104e3f"/>
          <Badge title="web" color="#10304e"/>
          <p>
            Application to <strong>present budget data in bubble chart form</strong>.
            Made in Vue as the front-end stack and Flask as the back-end stack.
          </p>
        </HeaderContent>
        <HeaderContent title="Coba Kalau Bisa" img={require("./../assets/img/projects/ckb.jpg")}>
          <Badge title="Flash" color="#104e3f"/>
          <Badge title="standalone" color="#10304e"/>
          <Badge title="solo project"/>
          <p>
            Just-for-fun quiz game made in Flash. 
            The gameplay is really easy. Simply <strong>pick the correct answer to advance.</strong> One mistake will
            cost you a life, though. <strong>Heavily inspired from The Impossible Quiz by Splapp-me-do</strong>.
            Consists of two versions with 100 questions each.
          </p>
          <div className="button-section">
            <ButtonModule
              onClick={()=>window.open("https://bit.ly/CKBisa1dl", "_blank")}
              title="download v1 (.exe)"
            />
            <ButtonModule
              onClick={()=>window.open("https://bit.ly/CKBisa2dl", "_blank")}
              title="download v2 (.exe)"
            />
          </div>
        </HeaderContent>
      </div>
    )
  }
}
export default Projects